import { useTranslation } from "react-i18next";
import { Space } from "antd";

import { BodyContainer, FooterContainer, ScrollableContainer } from "drawers/channex_drawer_v2";

import { AntdFormField } from "components/forms/inputs/hook_form/_v2/_base/antd_form_field";
import { FormInput } from "components/forms/inputs/hook_form/_v2/input";
import { FormSelect } from "components/forms/inputs/hook_form/_v2/select";
import { SubmitButton } from "components/forms/inputs/hook_form/_v2/submit_button";
import { StyledForm } from "components/forms/styled_form";

export const UserRequestLimitFormInner = ({ isUpdate, isSubmitting }) => {
  const { t } = useTranslation();

  return (
    <BodyContainer>
      <ScrollableContainer padding="0 16px">
        <StyledForm>
          <FormSelect
            name="path"
            label="Scenario"
            placeholder="Select Scenario"
            options={[
              {
                label: "BookingRevisions.FeedScenario",
                value: "GET /api/v1/booking_revisions/feed",
              },
              {
                label: "Bookings.FindScenario",
                value: "GET /api/v1/bookings/:id",
              },
              {
                label: "Bookings.ListScenario",
                value: "GET /api/v1/bookings",
              },
              {
                label: "RoomTypes.FindScenario",
                value: "GET /api/v1/room_types/:id",
              },
              {
                label: "RoomTypes.ListScenario",
                value: "GET /api/v1/room_types",
              },
              {
                label: "RatePlans.FindScenario",
                value: "GET /api/v1/rate_plans/:id",
              },
              {
                label: "RatePlans.ListScenario",
                value: "GET /api/v1/rate_plans",
              },
              {
                label: "Properties.ListScenario",
                value: "GET /api/v1/properties",
              },
            ]}
          />
          <FormSelect
            disabled={isUpdate}
            name="entity"
            label="Entity Type"
            placeholder="Select Entity Type"
            options={[{
              value: "user",
              label: "User",
            }, {
              value: "billing_account",
              label: "Billing Account",
            }, {
              value: "user_api_key",
              label: "API Key",
            }]}
          />
          <FormInput
            disabled={isUpdate}
            name="limitedId"
            label="Entity ID"
            placeholder="UUID of User, Billing Account or API Key"
          />
          <FormInput
            name="quota"
            label="Allowed Requests"
            placeholder="1000"
            type="number"
          />
          <AntdFormField
            label="Timeframe"
          >
            <Space.Compact style={{ width: "100%" }}>
              <FormInput
                name="timeframe"
                placeholder="Enter Timeframe, e.g., 1"
                type="number"
                grouped
              />
              <FormSelect
                name="timeframeType"
                placeholder="Select Timeframe Unit"
                grouped
                options={[{
                  value: "second",
                  label: "Second",
                }, {
                  value: "minute",
                  label: "Minute",
                }, {
                  value: "hour",
                  label: "Hour",
                }]}
              />
            </Space.Compact>
          </AntdFormField>
        </StyledForm>
      </ScrollableContainer>
      <FooterContainer>
        <SubmitButton layout={false} loading={isSubmitting}>
          {t("common:actions:save")}
        </SubmitButton>
      </FooterContainer>
    </BodyContainer>
  );
};
