import { AppForm } from "components/hook_form";

import { RoomFormInner } from "./room_form_inner";
import { validationSchema } from "./validation_schema";

export const RoomForm = ({ initialValue, roomTypes, ratePlans, currency, errors, onSubmit }) => {
  return (
    <AppForm
      initialValue={initialValue}
      errors={errors}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <RoomFormInner
        roomTypes={roomTypes}
        ratePlans={ratePlans}
        currency={currency}
      />
    </AppForm>
  );
};
