import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Flex, List } from "antd";

import CRUDTopBar from "components/crud_table/crud_top_bar";

import { EmptyData } from "./states/empty_data";
import { EmptySearchResults } from "./states/empty_search_results";
import { Error } from "./states/error";
import { Loading } from "./states/loading";

const InnerList = ({ items, searchQuery, createMessageText, emptyMessage, isLoading, isError, isFetching, filterFn, renderItem, onCreateClick }) => {
  const filteredItems = useMemo(() => {
    if (!items) {
      return [];
    }

    if (!searchQuery) {
      return items;
    }

    const normalizedSearchQuery = (searchQuery || "").toLowerCase();

    return items.filter((item) => filterFn(item, normalizedSearchQuery));
  }, [items, searchQuery, filterFn]);

  if (isLoading) {
    return (
      <Loading />
    );
  }

  if (isError) {
    return (
      <Error />
    );
  }

  if (filteredItems.length === 0 && searchQuery) {
    return (
      <EmptySearchResults />
    );
  }

  if (filteredItems.length === 0) {
    return (
      <EmptyData
        createMessageText={createMessageText}
        emptyMessage={emptyMessage}
        onCreateClick={onCreateClick}
      />
    );
  }

  return (
    <List
      loading={isFetching}
      dataSource={filteredItems}
      renderItem={renderItem}
    />
  );
};

export const TabList = (props) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = React.useState(null);

  const {
    itemsQuery,
    filterFn,
    renderItem,
    emptyMessage,
    createMessageText,
    onCreateClick,
  } = props;

  const { data, isLoading, isError, isFetching } = itemsQuery;

  return (
    <Flex vertical>
      <CRUDTopBar
        refreshAction={false}
        actions={(
          <Button icon={<PlusCircleOutlined />} type="primary" onClick={onCreateClick}>{t("general:action:create")}</Button>
        )}
        searchQuery={searchQuery}
        onSearchChange={setSearchQuery}
      />

      <InnerList
        items={data}
        searchQuery={searchQuery}
        emptyMessage={emptyMessage}
        createMessageText={createMessageText}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        filterFn={filterFn}
        renderItem={renderItem}
        onCreateClick={onCreateClick}
      />
    </Flex>
  );
};

export const Item = ({ children, ...rest }) => <List.Item data-cy="item" {...rest}>{children}</List.Item>;
