import React from "react";

import SubmitButton from "components/forms/buttons/submit_button";
import GlobalErrors from "components/forms/global_errors";
import FormInput from "components/forms/inputs/hook_form/form_input";
import FormSelect from "components/forms/inputs/hook_form/form_select";
import BottomActions from "components/forms/layout/drawer/bottom_actions/bottom_actions";
import { useAppForm } from "components/hook_form";

import { validationSchema } from "./validation_schema";

export const SUPPORTED_PROVIDERS = [{
  representation: "Default",
  value: "default",
}, {
  representation: "SMTP",
  value: "smtp",
}, {
  representation: "SMTP2GO",
  value: "smtp2go",
}, {
  representation: "CustomerIO",
  value: "customer_io",
}, {
  representation: "Dyn",
  value: "dyn",
}, {
  representation: "Gmail",
  value: "gmail",
}, {
  representation: "Local",
  value: "local",
}, {
  representation: "Logger",
  value: "logger",
}, {
  representation: "MailPace",
  value: "mail_pace",
}, {
  representation: "Mailgun",
  value: "mailgun",
}, {
  representation: "Mailjet",
  value: "mailjet",
}, {
  representation: "Mandrill",
  value: "mandrill",
}, {
  representation: "MsGraph",
  value: "ms_graph",
}, {
  representation: "OhMySmtp",
  value: "oh_my_smtp",
}, {
  representation: "SendGrid",
  value: "sendgrid",
}, {
  representation: "Brevo",
  value: "brevo",
}, {
  representation: "Sendinblue",
  value: "sendinblue",
}, {
  representation: "Sendmail",
  value: "sendmail",
}, {
  representation: "Postmark",
  value: "postmark",
}, {
  representation: "SparkPost",
  value: "sparkpost",
}, {
  representation: "Amazon SES",
  value: "amazon_ses",
}, {
  representation: "Ex Aws Amazon SES",
  value: "ex_aws_amazon_ses",
}, {
  representation: "SocketLabs",
  value: "socket_labs",
}, {
  representation: "ProtonBridge",
  value: "proton_bridge",
}, {
  representation: "Scaleway",
  value: "scaleway",
}, {
  representation: "Test",
  value: "test",
}];

export function WhiteLabelEmailSettingsForm({ values, isLoading, onSubmit }) {
  const { handleSubmit, errors, control } = useAppForm({
    defaultValue: values || { provider: "default", isActive: true, settings: {} },
    validationSchema,
    fieldNames: ["fromMail", "fromName", "provider", "isActive"],
    submitHandler: onSubmit,
  });

  const isEdit = !!values?.id;

  return (
    <div>
      <div>
        <GlobalErrors hookForm errors={errors} />

        <FormInput
          name="fromMail"
          label="From Mail"
          errors={errors?.fromMail?.message}
          control={control}
        />
        <FormInput
          name="fromName"
          label="From Name"
          errors={errors?.fromName?.message}
          control={control}
        />
        <FormSelect
          name="provider"
          label="Provider"
          control={control}
          errors={errors?.provider?.message}
          options={SUPPORTED_PROVIDERS}
        />
        <FormSelect
          name="isActive"
          label="Active"
          control={control}
          errors={errors?.isActive?.message}
          options={[{ value: true, representation: "Yes" }, { value: false, representation: "No" }]}
        />
      </div>
      {isEdit && (
        <div>
          <legend>Settings</legend>
          <pre>
            {JSON.stringify(values.settings, null, 2)}
          </pre>
        </div>
      )}

      <BottomActions>
        <SubmitButton onClick={() => { handleSubmit(); }} loading={isLoading}>
          Save
        </SubmitButton>
      </BottomActions>
    </div>
  );
}
