import { useTranslation } from "react-i18next";

import { BodyContainer, FooterContainer, ScrollableContainer } from "drawers/channex_drawer_v2";

import SubmitButton from "components/forms/buttons/submit_button";
import { FormCheckbox } from "components/forms/inputs/hook_form/_v2/checkbox";
import { FormTextArea } from "components/forms/inputs/hook_form/_v2/textarea";
import { StyledForm } from "components/forms/styled_form";
import { useAppForm } from "components/hook_form";

import { InlineCheckboxLabel } from "./inline_checkbox_label";
import { validationSchema } from "./validation_schema";

const buildDefaultValue = (initialValue) => {
  const defaultValue = { ...initialValue };

  const booleanFields = ["returnKeys", "turnThingsOff", "throwTrash", "lockUp", "gatherTowels", "additionalRequests"];

  booleanFields.forEach((field) => {
    if (!defaultValue[field]) {
      defaultValue[field] = {
        isPresent: false,
      };
    }
  });

  return defaultValue;
};

export const ListingCheckoutTasksForm = ({ initialValue, errors: parentErrors, isSubmitting, onSubmit }) => {
  const { t } = useTranslation();

  const { control, watch, handleSubmit } = useAppForm({
    defaultValue: buildDefaultValue(initialValue),
    submitHandler: async (formValue) => {
      await onSubmit(formValue);
    },
    errors: parentErrors,
    validationSchema,
    fieldNames: ["returnKeys", "turnThingsOff", "throwTrash", "lockUp", "gatherTowels", "additionalRequests"],
    shouldUnregister: true,
  });

  const fields = watch();

  return (
    <BodyContainer>
      <ScrollableContainer padding="0 16px">
        <StyledForm>
          <InlineCheckboxLabel label={t("listings:forms:fields:return_keys:label")}>
            <FormCheckbox
              name="returnKeys.isPresent"
              control={control}
            />
          </InlineCheckboxLabel>
          {fields.returnKeys.isPresent && (
            <FormTextArea
              name="returnKeys.taskDetail"
              placeholder={t("common:terms:description")}
              control={control}
            />
          )}
          <InlineCheckboxLabel label={t("listings:forms:fields:turn_things_off:label")}>
            <FormCheckbox
              name="turnThingsOff.isPresent"
              control={control}
            />
          </InlineCheckboxLabel>
          {fields.turnThingsOff.isPresent && (
            <FormTextArea
              name="turnThingsOff.taskDetail"
              placeholder={t("common:terms:description")}
              control={control}
            />
          )}
          <InlineCheckboxLabel label={t("listings:forms:fields:throw_trash:label")}>
            <FormCheckbox
              name="throwTrash.isPresent"
              control={control}
            />
          </InlineCheckboxLabel>
          {fields.throwTrash.isPresent && (
            <FormTextArea
              name="throwTrash.taskDetail"
              placeholder={t("common:terms:description")}
              control={control}
            />
          )}
          <InlineCheckboxLabel label={t("listings:forms:fields:lock_up:label")}>
            <FormCheckbox
              name="lockUp.isPresent"
              control={control}
            />
          </InlineCheckboxLabel>
          {fields.lockUp.isPresent && (
            <FormTextArea
              name="lockUp.taskDetail"
              placeholder={t("common:terms:description")}
              control={control}
            />
          )}
          <InlineCheckboxLabel label={t("listings:forms:fields:gather_towels:label")}>
            <FormCheckbox
              name="gatherTowels.isPresent"
              control={control}
            />
          </InlineCheckboxLabel>
          {fields.gatherTowels.isPresent && (
            <FormTextArea
              name="gatherTowels.taskDetail"
              placeholder={t("common:terms:description")}
              control={control}
            />
          )}
          <InlineCheckboxLabel label={t("listings:forms:fields:additional_requests:label")}>
            <FormCheckbox
              name="additionalRequests.isPresent"
              control={control}
            />
          </InlineCheckboxLabel>
          {fields.additionalRequests.isPresent && (
            <FormTextArea
              name="additionalRequests.taskDetail"
              placeholder={t("common:terms:description")}
              control={control}
            />
          )}
        </StyledForm>
      </ScrollableContainer>
      <FooterContainer>
        <SubmitButton onClick={handleSubmit} layout={false} loading={isSubmitting}>
          {t("common:actions:save")}
        </SubmitButton>
      </FooterContainer>
    </BodyContainer>
  );
};
