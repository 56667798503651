import React from "react";
import { Steps } from "antd";

export const Stepper = ({ step }) => {
  return (
    <Steps
      size="small"
      type="navigation"
      style={{ padding: "0 16px 16px 16px" }}
      current={step}
      items={[
        {
          title: "Property & Room Type",
        },
        {
          title: "Complete Listing Details",
        },
      ]}
    />
  );
};
