import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Table } from "antd";
import store from "store";

import Loading from "components/loading/loading";
import NoDataPlaceholder from "components/no_data_placeholder";
import { showSuccessMessage } from "components/toasts/messages";

import useDrawer from "hooks/use_drawer";

import showErrorMessage from "utils/show_error_message";

import ApplicationUsersDrawer from "./application_users_drawer";

import sharedStyles from "components/property_form/components/policy_view/policy_view.module.css";

const DROPDOWN_TRIGGER = ["click"];

const { AdminApplications } = store;

export const useApplications = () => {
  return useQuery({
    queryKey: ["adminApplications"],
    queryFn: () => AdminApplications.list(),
  });
};

const useApplicationChangeModeMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params) => {
      return AdminApplications.changeAccessMode(params);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["adminApplications"] });
      showSuccessMessage();
    },
    onError: showErrorMessage,
  });
};

export default function ApplicationAccessSubPage() {
  const { t } = useTranslation();

  const { isOpen: isDrawerOpen, open: openApplicationUsersDrawer, close: closeApplicationUsersDrawer, payload: managePayload } = useDrawer();
  const { data, isLoading, isFetching } = useApplications();
  const changeModeMutation = useApplicationChangeModeMutation();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <div className={sharedStyles.tableContainer}>
        {data.length ? (
          <Table
            loading={isFetching}
            pagination={false}
            showHeader
            dataSource={data}
            columns={[{
              title: "Title",
              dataIndex: "title",
              key: "title",
            }, {
              title: "Access",
              dataIndex: "accessMode",
              key: "accessMode",
            }, {
              key: "action",
              align: "right",
              render: (value, record) => {
                const items = [{
                  key: "edit",
                  onClick: () => { openApplicationUsersDrawer(record); },
                  label: (
                    <div>
                      Manage Access
                    </div>
                  ),
                }, {
                  type: "divider",
                }, {
                  key: "change_access_mode",
                  label: (
                    <div>
                      Access Mode
                    </div>
                  ),
                  children: [
                    record.accessMode !== "everyone" && {
                      key: "everyone",
                      onClick: () => {
                        changeModeMutation.mutate({ applicationId: record.id, accessMode: "everyone" });
                      },
                      label: (
                        <div>Everyone</div>
                      ),
                    },
                    record.accessMode !== "owner" && {
                      key: "owner",
                      onClick: () => {
                        changeModeMutation.mutate({ applicationId: record.id, accessMode: "owner" });
                      },
                      label: "Owner",
                    },
                    record.accessMode !== "restricted" && {
                      key: "restricted",
                      onClick: () => {
                        changeModeMutation.mutate({ applicationId: record.id, accessMode: "restricted" });
                      },
                      label: "Restricted",
                    },
                  ],
                }];

                return (
                  <Dropdown menu={{ items }} trigger={DROPDOWN_TRIGGER}>
                    <Button type="link">
                      {t("general:actions")} <DownOutlined />
                    </Button>
                  </Dropdown>
                );
              },
            }]}
            rowKey="id"
          />
        ) : (
          <NoDataPlaceholder />
        )}
      </div>

      <ApplicationUsersDrawer
        visible={isDrawerOpen}
        value={managePayload}
        onClose={() => {
          closeApplicationUsersDrawer();
        }}
      />
    </div>
  );
}
