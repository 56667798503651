import { useMutation } from "react-query";
import { reportsApiClient } from "store/api_clients/reports";

import { formatDateTime } from "utils/dates";

export const useDownloadReport = ({ dateType, dateFrom, dateTo, propertyId }) => {
  return useMutation({
    queryKey: ["channels_report", formatDateTime(dateFrom), formatDateTime(dateTo), dateType, propertyId],
    mutationFn: async () => {
      const data = await reportsApiClient.channelsBreakdown({ dateType, dateFrom, dateTo, propertyId, format: "csv" });

      const objectUrl = window.URL.createObjectURL(new Blob([data]));
      const anchor = document.createElement("a");
      anchor.href = objectUrl;
      anchor.download = "report.csv";

      document.body.appendChild(anchor);

      anchor.click();
      document.body.removeChild(anchor);

      window.URL.revokeObjectURL(objectUrl);
    },
    retry: false,
    refetchOnWindowFocus: false,
    retryOnMount: false,
    refetchOnMount: false,
  });
};
