import { useFormState } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormCurrency } from "components/forms/inputs/hook_form/_v2/currency";
import Legend from "components/forms/layout/legend/legend";

const DaysContent = ({ name, days, currency }) => {
  const { t } = useTranslation();

  return (
    (days).sort().map((date) => (
      <FormCurrency
        key={date}
        name={`${name}.${date}`}
        label={date}
        placeholder={t("common:forms:fields:amount:placeholder")}
        currency={currency}
      />
    ))
  );
};

export const Days = ({ name, days, currency }) => {
  const { t } = useTranslation();

  const formState = useFormState();
  const daysError = formState.errors[name];

  return (
    <>
      <Legend error={daysError?.message}>{t("common:headings:days")}</Legend>
      <DaysContent name={name} days={days} currency={currency} />
    </>
  );
};
