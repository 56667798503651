import { useMutation, useQueryClient } from "react-query";
import { adminUserRequestLimitsClient } from "store/api_clients/admin/user_request_limits";

import { showSuccessMessage } from "components/toasts/messages";

export function useDeleteMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id) => adminUserRequestLimitsClient.delete(id),
    onSuccess: () => {
      queryClient.invalidateQueries("user_request_limits");
      showSuccessMessage();
    },
  });
}
