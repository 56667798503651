import * as yup from "yup";

yup.addMethod(yup.string, "validNumber", function (validationMessage = "should be a valid number") {
  return this.test(
    "valid-number",
    validationMessage,
    (value) => !value || /^-?\d+(\.\d+)?$/.test(value),
  );
});

export { yup };
