import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form } from "antd";
import _ from "lodash";
import * as yup from "yup";

import errorMessages from "config/constants/errors";

import BillingAccountSubscription from "components/billing_account/subscription";
import BillingAccountUsage from "components/billing_account/usage";
import ChannexForm from "components/channex_form";
import SubmitButton from "components/forms/buttons/submit_button";
import FormikFormInput from "components/forms/inputs/formik/form_input";
import FormikSelect from "components/forms/inputs/formik/form_select";
import FormikTextareaInput from "components/forms/inputs/formik/form_textarea";

import IdItem from "../../group_management/components/group_form/items/id";

import styles from "styles/form_in_drawer.module.css";

class BillingAccountForm extends Component {
  VALIDATION_SCHEMA = yup.object().shape({
    company_name: yup.string().trim().required(errorMessages.required()),
    contact_person_first_name: yup.string().nullable().trim(),
    contact_person_last_name: yup.string().nullable().trim(),
    notes: yup.string().nullable().trim(),
  });

  render() {
    const { t, componentRef, value, submitting, errors, onChange, onSubmit } = this.props;

    return (
      <>
        <ChannexForm
          defaultValue={this.DEFAULT_VALUE}
          value={value}
          errors={errors}
          componentRef={componentRef}
          validationSchema={this.VALIDATION_SCHEMA}
          onChange={onChange}
        >
          {({ handleSubmit }) => (
            <Form onFinish={handleSubmit}>
              {value?.id && <IdItem id={value?.id} />}
              <FormikFormInput
                name="company_name"
                placeholder={t("billing_account_drawer:form:company_name:placeholder")}
                label={t("billing_account_drawer:form:company_name:label")}
              />
              <FormikFormInput
                name="contact_person_first_name"
                placeholder={t("billing_account_drawer:form:contact_person_first_name:placeholder")}
                label={t("billing_account_drawer:form:contact_person_first_name:label")}
              />
              <FormikFormInput
                name="contact_person_last_name"
                placeholder={t("billing_account_drawer:form:contact_person_last_name:placeholder")}
                label={t("billing_account_drawer:form:contact_person_last_name:label")}
              />
              <FormikFormInput
                name="technical_support_email"
                placeholder={t("billing_account_drawer:form:technical_support_email:placeholder")}
                label={t("billing_account_drawer:form:technical_support_email:label")}
              />
              <FormikTextareaInput
                name="notes"
                placeholder={t("billing_account_drawer:form:notes:placeholder")}
                label={t("billing_account_drawer:form:notes:label")}
              />
              <FormikSelect
                name="min_stay_type"
                placeholder={t("billing_account_drawer:form:min_stay_type:placeholder")}
                label={t("billing_account_drawer:form:min_stay_type:label")}
                options={[
                  { value: "both", representation: "Both" },
                  { value: "arrival", representation: "Min Stay Arrival" },
                  { value: "through", representation: "Min Stay Through" },
                ]}
              />
              <legend>{t("billing_account_drawer:form:limits_legend")}</legend>
              <FormikFormInput
                name="max_count_of_room_types"
                type="number"
                placeholder={t("billing_account_drawer:form:max_count_of_room_types:placeholder")}
                label={t("billing_account_drawer:form:max_count_of_room_types:label")}
              />
              <FormikFormInput
                name="max_count_of_rate_plans"
                type="number"
                placeholder={t("billing_account_drawer:form:max_count_of_rate_plans:placeholder")}
                label={t("billing_account_drawer:form:max_count_of_rate_plans:label")}
              />
              <FormikFormInput
                name="max_count_of_property_users"
                type="number"
                placeholder={t("billing_account_drawer:form:max_count_of_property_users:placeholder")}
                label={t("billing_account_drawer:form:max_count_of_property_users:label")}
              />
              <FormikFormInput
                name="max_count_of_group_users"
                type="number"
                placeholder={t("billing_account_drawer:form:max_count_of_group_users:placeholder")}
                label={t("billing_account_drawer:form:max_count_of_group_users:label")}
              />
              <FormikFormInput
                name="max_count_of_occupancies"
                type="number"
                placeholder={t("billing_account_drawer:form:max_count_of_occupancies:placeholder")}
                label={t("billing_account_drawer:form:max_count_of_occupancies:label")}
              />
              <FormikFormInput
                name="billable_hotel_rate_plans"
                type="number"
                placeholder={t("billing_account_drawer:form:billable_hotel_rate_plans:placeholder")}
                label={t("billing_account_drawer:form:billable_hotel_rate_plans:label")}
              />
              <FormikFormInput
                name="billable_hotel_room_types"
                type="number"
                placeholder={t("billing_account_drawer:form:billable_hotel_room_types:placeholder")}
                label={t("billing_account_drawer:form:billable_hotel_room_types:label")}
              />
              <FormikFormInput
                name="billable_vr_rate_plans"
                type="number"
                placeholder={t("billing_account_drawer:form:billable_vr_rate_plans:placeholder")}
                label={t("billing_account_drawer:form:billable_vr_rate_plans:label")}
              />
              <FormikFormInput
                name="billable_vr_room_types"
                type="number"
                placeholder={t("billing_account_drawer:form:billable_vr_room_types:placeholder")}
                label={t("billing_account_drawer:form:billable_vr_room_types:label")}
              />
            </Form>
          )}
        </ChannexForm>

        <legend>Usage</legend>
        <BillingAccountUsage
          usage={value.relationships.usage?.data?.attributes}
          billingAccountId={value.id}
        />
        <BillingAccountSubscription
          subscription={value.relationships.billing_account_subscription?.data?.attributes}
        />

        <div className={styles.actions}>
          <SubmitButton loading={submitting} onClick={onSubmit}>
            {t("general:action:save")}
          </SubmitButton>
        </div>
      </>
    );
  }
}

export default withTranslation()(BillingAccountForm);
