import React from "react";
import { useTranslation } from "react-i18next";

import currencyOptions from "config/constants/currency_options";

import { BodyContainer, FooterContainer, ScrollableContainer } from "drawers/channex_drawer_v2";

import SubmitButton from "components/forms/buttons/submit_button";
import GlobalErrors from "components/forms/global_errors";
import { FormSelect } from "components/forms/inputs/hook_form/_v2/select";
import { StyledForm } from "components/forms/styled_form";
import { useAppForm } from "components/hook_form";

import { hopperHomesErrorParser } from "../../../../api/error_parser/hopper_homes_error_parser";

import { Fees } from "./fees";
import { Taxes } from "./taxes";
import { validationSchema } from "./validation_schema";
import { toApi, toForm } from "./value_converter";

export const PricingSettingsForm = ({ defaultValue, isLoading, onSubmit }) => {
  const { t } = useTranslation();

  const { handleSubmit, errors, control, setValue } = useAppForm({
    defaultValue: toForm(defaultValue),
    submitHandler: async (formValue) => {
      const params = toApi(formValue);
      await onSubmit(params);
    },
    apiErrorParser: (error) => hopperHomesErrorParser.parse(error.body.errors, t),
    validationSchema,
    nullifyEmptyFields: true,
    ensureFieldsPresent: true,
    fieldNames: ["currency", "pricingModel", "cancellationPolicy", "fees", "taxes"],
  });

  const cancellationPolicyOptions = [{
    value: null,
    label: t("common:dicts:options:none"),
  }, {
    value: "flexible",
    label: t("listings:dicts:cancellation_policies:flexible"),
  }, {
    value: "moderate",
    label: t("listings:dicts:cancellation_policies:moderate"),
  }, {
    value: "strict",
    label: t("listings:dicts:cancellation_policies:strict"),
  }, {
    value: "non-refundable",
    label: t("listings:dicts:cancellation_policies:non_refundable"),
  }];

  return (
    <BodyContainer>
      <ScrollableContainer padding="0 16px">
        <StyledForm>
          <GlobalErrors hookForm errors={errors} />

          <FormSelect
            name="currency"
            label={t("common:terms:currency")}
            placeholder={t("common:forms:fields:currency:placeholder")}
            disabled
            options={currencyOptions({ only: ["USD"] })}
            control={control}
          />

          <FormSelect
            name="pricingModel"
            label={t("common:terms:pricing_model")}
            placeholder={t("common:forms:fields:pricing_model:placeholder")}
            disabled
            options={[{ value: "nightly_pricing", label: "Nightly Pricing" }]}
            control={control}
          />

          <FormSelect
            name="cancellationPolicy"
            label={t("common:terms:cancellation_policy")}
            placeholder={t("common:forms:fields:cancellation_policy:placeholder")}
            allowClear
            options={cancellationPolicyOptions}
            control={control}
          />

          <Fees
            name="fees"
            errors={errors?.fees}
            setValue={setValue}
            control={control}
          />

          <Taxes
            name="taxes"
            errors={errors?.taxes}
            setValue={setValue}
            control={control}
          />
        </StyledForm>
      </ScrollableContainer>
      <FooterContainer>
        <SubmitButton onClick={handleSubmit} loading={isLoading} layout={false}>
          {t("common:actions:save")}
        </SubmitButton>
      </FooterContainer>
    </BodyContainer>
  );
};
