import { isDayjs } from "dayjs";
import i18n from "i18next";

import dayjs from "utils/dayjs";

export { dayjs };

export const parseDate = (value, options = { native: true }) => {
  const date = dayjs(value);

  if (!date.isValid()) {
    throw new Error(`Invalid date: ${value}`);
  }

  if (!options.native) {
    return date;
  }

  return date.toDate();
};

export const safeParseDate = (value, options = { native: true }) => {
  const date = dayjs(value);

  if (!date.isValid()) {
    return null;
  }

  if (!options.native) {
    return date;
  }

  return date.toDate();
};

export const now = () => {
  return dayjs().toDate();
};

export const nowUTC = () => {
  return dayjs.utc().toDate();
};

export const nowWithOffset = (...offset) => {
  return dayjs().add(...offset).toDate();
};

export const today = () => {
  return dayjs().startOf("day").toDate();
};

export const todayUTC = () => {
  return dayjs.utc().startOf("day").toDate();
};

export const todayWithOffset = (...offset) => {
  return dayjs().add(...offset).startOf("day").toDate();
};

export const startOfDay = (date) => {
  return dayjs(date).startOf("day").toDate();
};

export const addDays = (date, offset) => {
  return dayjs(date).add(offset, "day").toDate();
};

export const addHours = (date, offset) => {
  return dayjs(date).add(offset, "hours").toDate();
};

export const diffDays = (start, end) => {
  return dayjs(end).diff(dayjs(start), "day");
};

export const isBefore = (date1, date2) => {
  return dayjs(date1).isBefore(date2);
};

export const isBetween = (date, start, end) => {
  // Parameter 4 is a string with two characters; '[' means inclusive, '(' exclusive
  // '()' excludes start and end date (default)
  // '[]' includes start and end date
  // '[)' includes the start date but excludes the stop
  return dayjs(date).isBetween(start, end, null, "[]");
};

export const formatDate = (date) => {
  return dayjs(date).format("YYYY-MM-DD");
};

export const formatDateTime = (dateTime) => {
  return dayjs(dateTime).toISOString();
};

export const format = (date, fmt) => {
  return dayjs(date).format(fmt);
};

export const formatToLocal = (date, fmt) => {
  return dayjs.utc(date).local().format(fmt);
};

export const toLocalFromNow = (date) => {
  return dayjs.utc(date).local().fromNow();
};

export const bookingDate = (date, dateFormat) => {
  if (!date) {
    return i18n.t("general:not_applicable");
  }

  return dayjs(date).format(dateFormat);
};

export const sortStringDates = (dates) => {
  return dates.sort((a, b) => {
    return dayjs(a).diff(dayjs(b));
  });
};

export const daysBetween = (start, end) => {
  const days = [];

  if (!start || !end) {
    throw new Error("Start and end dates are required");
  }

  const endDate = dayjs(end);
  let currentDate = dayjs(start);

  while (currentDate.isSameOrBefore(endDate)) {
    days.push(currentDate.format("YYYY-MM-DD"));
    currentDate = currentDate.add(1, "day");
  }

  return days;
};

export const ensureDayjsDate = (date) => {
  if (isDayjs(date)) {
    return date;
  }

  return parseDate(date, { native: false });
};

export { isDayjs };
