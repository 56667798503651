import { useMutation, useQueryClient } from "react-query";
import { adminUserRequestLimitsClient } from "store/api_clients/admin/user_request_limits";

import { showSuccessMessage } from "components/toasts/messages";

export function useUpdateMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params) => adminUserRequestLimitsClient.update(params),
    onSuccess: () => {
      queryClient.invalidateQueries("user_request_limits");
      showSuccessMessage();
    },
  });
}
