import { camelCase, isPlainObject } from "lodash";

import { flattenObject } from "./flatten_object";

const transformErrors = (errors, options) => {
  const { camelCaseFields } = options;

  if (Array.isArray(errors) && typeof errors[0] === "string") {
    const errorText = errors[0];

    return errorText;
  }

  if (Array.isArray(errors) && isPlainObject(errors[0])) {
    return errors.map((error) => transformErrors(error, options));
  }

  return Object.keys(errors).reduce((resultErrors, fieldName) => {
    const result = {
      ...resultErrors,
    };

    if (camelCaseFields) {
      result[camelCase(fieldName)] = transformErrors(errors[fieldName], options);
    } else {
      result[camelCase(fieldName)] = transformErrors(errors[fieldName], options);
      result[fieldName] = transformErrors(errors[fieldName], options);
    }

    return result;
  }, {});
};

const parseApiErrors = (error, options = {}) => {
  let errors = error?.errors?.details || {};

  const { root } = options;

  // If root is provided, we need to extract errors from the root object
  // but on api level there is inconsistency, and some errors are nested, some are not
  if (root && errors[root]) {
    errors = errors[root];
  }

  const resultErrors = flattenObject({ obj: transformErrors(errors, options) });

  return resultErrors;
};

export default parseApiErrors;
