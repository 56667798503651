import React from "react";

import { BodyContainer, FooterContainer, ScrollableContainer } from "drawers/channex_drawer_v2";

export const ScrollBox = ({
  header,
  content,
  footer,
}) => {
  return (
    <BodyContainer>
      {header && header}
      <ScrollableContainer padding="0 16px">
        {content}
      </ScrollableContainer>
      {footer && <FooterContainer>{footer}</FooterContainer>}
    </BodyContainer>
  );
};
