import attributesExtractor from "../utils/attributes_extractor";
import { convertToCamelCase, convertToSnakeCase } from "../utils/case_converter";

import { httpClient } from "./http_client";

export const bookingsApiClient = {
  get: async (bookingId) => {
    const path = `bookings/${bookingId}`;
    const response = await httpClient.send("GET", path);

    return convertToCamelCase(attributesExtractor(response.data));
  },

  create: async (params) => {
    const body = convertToSnakeCase({
      booking: params,
    });

    const response = await httpClient.send("POST", "bookings", body);

    return convertToCamelCase(attributesExtractor(response.data));
  },

  update: async ({ id, params }) => {
    const path = `bookings/${id}`;

    const body = convertToSnakeCase({
      booking: params,
    });

    const response = await httpClient.send("PUT", path, body);

    return convertToCamelCase(attributesExtractor(response.data));
  },

  noShowReport: async ({ bookingId, collectFee, feeAmount }) => {
    const params = {
      waivedFees: collectFee,
    };

    if (typeof feeAmount !== "undefined" && feeAmount !== null) {
      params.penaltyAmount = feeAmount;
    }

    const body = convertToSnakeCase({ noShowReport: params });
    const path = "bookings/:bookingId/no_show".replace(":bookingId", bookingId);

    const response = await httpClient.send("POST", path, body);

    return convertToCamelCase(attributesExtractor(response.data));
  },
};
