import React from "react";
import { useTranslation } from "react-i18next";

import { BodyContainer, FooterContainer, ScrollableContainer } from "drawers/channex_drawer_v2";

import SubmitButton from "components/forms/buttons/submit_button";
import GlobalErrors from "components/forms/global_errors";
import { FormCheckbox } from "components/forms/inputs/hook_form/form_checkbox";
import FormInputNumber from "components/forms/inputs/hook_form/form_input_number";
import { FormWeekdays, THREE_CHAR_VALUE_MODE, THREE_CHAR_WEEKDAY_VALUES } from "components/forms/inputs/hook_form/form_weekdays";
import { StyledForm } from "components/forms/styled_form";
import { useAppForm } from "components/hook_form";

import { hopperHomesErrorParser } from "../../../../api/error_parser/hopper_homes_error_parser";

const DEFAULT_VALUE = {
  available: false,
  checkInDays: THREE_CHAR_WEEKDAY_VALUES,
};

const toForm = (data) => {
  const normalizedData = { ...data };

  if (!normalizedData.checkInDays) {
    normalizedData.checkInDays = [...DEFAULT_VALUE.checkInDays];
  }

  if (typeof normalizedData.available !== "boolean") {
    normalizedData.available = DEFAULT_VALUE.available;
  }

  return normalizedData;
};

export const DefaultRulesForm = ({ defaultValue, isLoading, onSubmit }) => {
  const { t } = useTranslation();

  const { handleSubmit, errors, control } = useAppForm({
    defaultValue: toForm(defaultValue),
    submitHandler: async (formValue) => {
      await onSubmit(formValue);
    },
    apiErrorParser: (error) => hopperHomesErrorParser.parse(error.body.errors, t),
    fieldNames: ["checkInDays", "available", "minStay", "maxStay", "minLeadTimeHours", "maxLeadTimeDays"],
    nullifyEmptyFields: true,
  });

  return (
    <BodyContainer>
      <ScrollableContainer padding="0 16px">
        <StyledForm>
          <GlobalErrors hookForm errors={errors} />

          <FormWeekdays
            control={control}
            name="checkInDays"
            valueMode={THREE_CHAR_VALUE_MODE}
            label={t("listings:terms:check_in_days")}
          />

          <FormCheckbox
            control={control}
            name="available"
            label={t("listings:terms:available")}
          />

          <FormInputNumber
            control={control}
            name="minStay"
            label={t("listings:terms:min_stay")}
            placeholder={t("listings:forms:fields:min_stay:placeholder")}
          />

          <FormInputNumber
            control={control}
            name="maxStay"
            label={t("listings:terms:max_stay")}
            placeholder={t("listings:forms:fields:max_stay:placeholder")}
          />

          <FormInputNumber
            control={control}
            name="minLeadTimeHours"
            label={t("listings:terms:min_lead_time_hours")}
            placeholder={t("listings:forms:fields:min_lead_time_hours:placeholder")}
          />

          <FormInputNumber
            control={control}
            name="maxLeadTimeDays"
            label={t("listings:terms:max_lead_time_days")}
            placeholder={t("listings:forms:fields:max_lead_time_days:placeholder")}
          />
        </StyledForm>
      </ScrollableContainer>

      <FooterContainer>
        <SubmitButton onClick={handleSubmit} loading={isLoading} layout={false}>
          {t("common:actions:save")}
        </SubmitButton>
      </FooterContainer>
    </BodyContainer>
  );
};
