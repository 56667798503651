import { useQuery } from "react-query";
import { reportsApiClient } from "store/api_clients/reports";

import { formatDateTime } from "utils/dates";

const isAllDefined = (...args) => {
  return args.every((arg) => typeof arg !== "undefined" && arg !== null);
};

export const useReport = ({ dateType, dateFrom, dateTo, propertyId }) => {
  return useQuery({
    queryKey: ["channels_report", formatDateTime(dateFrom), formatDateTime(dateTo), dateType, propertyId],
    queryFn: () => reportsApiClient.channelsBreakdown({ dateType, dateFrom, dateTo, propertyId }),
    enabled: isAllDefined(dateType, dateFrom, dateTo, propertyId),
    retry: false,
    refetchOnWindowFocus: false,
    retryOnMount: false,
    refetchOnMount: false,
  });
};
