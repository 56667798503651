import React, { useEffect, useState } from "react";
import store from "store";

import BookingRevision from "components/bookings/booking_revision";
import Loading from "components/loading";

const { Bookings, Properties, RatePlans, RoomTypes } = store;

export default function AlterationBookingRequestContent({ eventData }) {
  const [bms, setBooking] = useState({ loading: true });
  const [_properties, setProperties] = useState({});
  const [ratePlans, setRatePlans] = useState({});
  const [roomTypes, setRoomTypes] = useState({});

  useEffect(
    function loadBooking() {
      const booking_id = eventData.payload.bms
        ? eventData.payload.bms.booking_id
        : eventData.payload.booking_id;

      Bookings.find(booking_id).then((booking) => {
        setBooking(booking);
      });
    },
    [eventData.payload],
  );

  useEffect(
    function loadProperty() {
      if (bms.property_id) {
        Properties.find(bms.property_id).then((property) => {
          const output = {};
          output[bms.property_id] = property;
          setProperties(output);
        });
      }
    },
    [bms.property_id],
  );

  useEffect(
    function loadRoomTypes() {
      if (bms.property_id) {
        RoomTypes.options({ property_id: bms.property_id }).then((roomTypesAnswer) => {
          setRoomTypes(
            roomTypesAnswer.reduce((acc, el) => {
              acc[el.id] = el;
              return acc;
            }, {}),
          );
        });
      }
    },
    [bms.property_id],
  );

  useEffect(
    function loadRatePlans() {
      if (bms.property_id) {
        RatePlans.options({ property_id: bms.property_id }).then((ratePlansAnswer) => {
          setRatePlans(
            ratePlansAnswer.reduce((acc, el) => {
              acc[el.id] = el;
              return acc;
            }, {}),
          );
        });
      }
    },
    [bms.property_id],
  );

  if (!bms.loading) {
    return (
      <>
        <h3>Original Booking</h3>
        <BookingRevision revision={bms} roomTypes={roomTypes} ratePlans={ratePlans} />
      </>
    );
  }

  return <Loading />;
}
