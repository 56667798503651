import { EditOutlined } from "@ant-design/icons";
import i18n from "i18next";

export const editMenuItem = ({ onClick }) => {
  const { t } = i18n;

  return {
    key: "edit",
    onClick,
    label: (
      <div data-cy="edit_menu_item">
        <EditOutlined /> {t("general:action:edit")}
      </div>
    ),
  };
};
