import validationMessages from "config/constants/errors";

import { yup } from "utils/validation/yup";

export const validationSchema = yup.object().shape({
  name: yup.string().required(validationMessages.required()),
  priceMode: yup.string().required(validationMessages.required()),
  pricePerUnit: yup.string()
    .validNumber(validationMessages.number())
    .required(validationMessages.required()),
  type: yup.string().required(validationMessages.required()),
});
