import _ from "lodash";

/* eslint-disable max-len */
const checkIfValidUUID = (str) => {
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  return regexExp.test(str);
};
/* eslint-enable max-len */

const isContainsDashes = (str) => {
  return str.includes("-");
};

const convertDataCase = (converter, data) => {
  if (!data || typeof data !== "object") {
    return data;
  }

  if (Array.isArray(data)) {
    return data.map((el) => convertDataCase(converter, el));
  }

  const dataWithUpdatedKeys = _.mapKeys(data, (_value, key) => {
    if (checkIfValidUUID(key)) {
      return key;
    }

    // dashes for now contains special keys like uuids, dates like 2024-12-17
    // we should not convert them, because in practice this is some value used as key
    if (isContainsDashes(key)) {
      return key;
    }

    return converter(key);
  });

  const updatedData = _.mapValues(dataWithUpdatedKeys, (value) => convertDataCase(converter, value));

  return updatedData;
};

export const convertToCamelCase = convertDataCase.bind(this, _.camelCase);
export const convertToSnakeCase = convertDataCase.bind(this, _.snakeCase);
