import React from "react";
import { useSelector } from "react-redux";
import { getActivePropertyId } from "store/storage/selectors/session_selector";

import { useProperty } from "data/use_property";

import { ChosenPropertyRequiredError } from "components/errors/chosen_property_required_error";
import { QueryError } from "components/errors/query_error";
import Flexbox from "components/flexbox";

import { ChannelsBreakdown } from "./channels_breakdown/channels_breakdown";
import { ControlsBar } from "./controls_bar/controls_bar";
import { Title } from "./title/title";
import { Totals } from "./totals/totals";
import { useDownloadReport } from "./use_download_report";
import { useReport } from "./use_report";
import { useReportQueryParams } from "./use_report_query_params";

export const ChannelsReport = () => {
  const { from, to, dateType, updateParams } = useReportQueryParams();

  const activePropertyId = useSelector(getActivePropertyId);
  const { property } = useProperty(activePropertyId);

  const dateFrom = from.startOf("day").utc().format();
  const dateTo = to.endOf("day").utc().format();

  const reportParams = { dateType, dateFrom, dateTo, propertyId: activePropertyId };

  const reportQuery = useReport(reportParams);
  const downloadReportMutation = useDownloadReport(reportParams);

  const { currency, totals, channels } = reportQuery.data || {};

  if (!activePropertyId) {
    return <ChosenPropertyRequiredError />;
  }

  return (
    <Flexbox flexDirection="column">
      <Title />
      <ControlsBar
        property={property}
        isFetching={reportQuery.isFetching}
        isLoading={reportQuery.isLoading}
        dateRange={[from, to]}
        dateType={dateType}
        onDateTypeChange={(newDateType) => {
          updateParams({ dateType: newDateType });
        }}
        onDateRangeChange={(newDates) => {
          updateParams({
            from: newDates[0],
            to: newDates[1],
          });
        }}
        onRefreshClick={() => reportQuery.refetch()}
      />
      {reportQuery.isError && (
        <QueryError />
      )}
      <Totals
        isLoading={reportQuery.isLoading}
        currency={currency}
        data={totals}
      />
      <ChannelsBreakdown
        isLoading={reportQuery.isLoading}
        currency={currency}
        data={channels}
        onDownloadCSVClick={() => downloadReportMutation.mutate()}
      />
    </Flexbox>
  );
};
