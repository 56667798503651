import { AppForm } from "components/hook_form";

import { useCreateMutation } from "./use_create_mutation";
import { useUpdateMutation } from "./use_update_mutation";
import { UserRequestLimitFormInner } from "./user_request_limit_form_inner";
import { validationSchema } from "./validation_schema";

export const UserRequestLimitForm = ({ initialValue, onFinish }) => {
  const createMutation = useCreateMutation();
  const updateMutation = useUpdateMutation();

  return (
    <AppForm
      initialValue={initialValue}
      validationSchema={validationSchema}
      shouldUnregister
      onSubmit={async (data) => {
        if (!initialValue?.id) {
          return createMutation.mutateAsync(data, { onSuccess: onFinish });
        }

        return updateMutation.mutateAsync({ id: initialValue.id, params: { id: initialValue.id, ...data } }, { onSuccess: onFinish });
      }}
    >
      <UserRequestLimitFormInner
        isUpdate={!!initialValue?.id}
        isSubmitting={createMutation.isLoading || updateMutation.isLoading}
      />
    </AppForm>
  );
};
