import { useQuery } from "react-query";
import store from "store";

import groupRatePlansByRoomTypeId from "../../utils/group_rate_plans_by_room_type_id";

const { RatePlans } = store;

export function useRatePlans(propertyId, { multiOccupancy = false, groupResults = false, enabled = true } = {}) {
  return useQuery({
    queryKey: ["ratePlans", propertyId],
    queryFn: () => RatePlans.options({ property_id: propertyId }, { multi_occupancy: multiOccupancy }),
    enabled,
    select: (data) => {
      if (groupResults) {
        return groupRatePlansByRoomTypeId(data);
      }

      return data;
    },
  });
}
