import { useTranslation } from "react-i18next";

import { ChannexDrawerV2 } from "drawers/channex_drawer_v2";

import { UserRequestLimitForm } from "./user_request_limit_form";

export const ManageDrawer = ({ visible, entity, onClose }) => {
  const { t } = useTranslation();

  const title = entity
    ? t("common:headings:edit")
    : t("common:headings:create");

  return (
    <ChannexDrawerV2 title={title} visible={visible} onClose={onClose}>
      <UserRequestLimitForm
        initialValue={entity}
        onFinish={onClose}
      />
    </ChannexDrawerV2>
  );
};
