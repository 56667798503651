import React from "react";
import { useTranslation } from "react-i18next";
import store from "store";
import attributesExtractor from "store/utils/attributes_extractor";

import SubmitButton from "components/forms/buttons/submit_button";
import GlobalErrors from "components/forms/global_errors";
import CustomSelect from "components/forms/inputs/select";
import BottomActions from "components/forms/layout/drawer/bottom_actions/bottom_actions";
import { useAppForm } from "components/hook_form";

import useGrantAccessAction from "./use_grant_access_action";
import validationSchema from "./validation_schema";

const { AdminUsers } = store;

export default function UserSelectForm({ applicationId, onFinish }) {
  const { t } = useTranslation();

  const { mutateAsync: grantAccess, isLoading: isGrantProcessing } = useGrantAccessAction();

  const { handleSubmit, errors, control } = useAppForm({
    validationSchema,
    fieldNames: ["userId"],
    submitHandler: async (formValue) => {
      const result = await grantAccess({
        applicationId,
        userId: formValue.userId,
      });

      onFinish(result);
    },
  });

  const userOptionsLoader = async (search) => {
    const filter = { email: { has: search } };

    const users = await AdminUsers.list(filter);
    const options = attributesExtractor(users.data)
      .map(({ id, email }) => ({ value: id, label: email }));

    return options;
  };

  return (
    <div>
      <div>
        <GlobalErrors hookForm errors={errors} />

        <CustomSelect
          name="userId"
          label="User"
          errors={errors?.userId?.message}
          optionsLoader={userOptionsLoader}
          control={control}
        />
      </div>

      <BottomActions>
        <SubmitButton onClick={() => { handleSubmit(); }} loading={isGrantProcessing}>
          {t("general:action:save")}
        </SubmitButton>
      </BottomActions>
    </div>
  );
}
