import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import currencyOptions from "config/constants/currency_options";

import { BodyContainer, FooterContainer, ScrollableContainer } from "drawers/channex_drawer_v2";

import { convertValueToCurrencyFormat, FormCurrency } from "components/forms/inputs/hook_form/_v2/currency";
import { FormDateTimePicker } from "components/forms/inputs/hook_form/_v2/date_time_picker";
import { FormSelect } from "components/forms/inputs/hook_form/_v2/select";
import { SubmitButton } from "components/forms/inputs/hook_form/_v2/submit_button";
import { FormTextArea } from "components/forms/inputs/hook_form/_v2/textarea";
import { StyledForm } from "components/forms/styled_form";

export const DepositFormInner = () => {
  const { t } = useTranslation();

  const currency = useWatch({ name: "currency" });
  const { getValues, setValue } = useFormContext();

  return (
    <BodyContainer>
      <ScrollableContainer padding="0 16px">
        <StyledForm>
          <FormSelect
            name="currency"
            label={t("common:terms:currency")}
            options={currencyOptions({ label: "label" })}
            onFieldChange={(newCurrency) => {
              const value = getValues("amount");
              const compatibleValue = convertValueToCurrencyFormat(value, newCurrency);

              setValue("amount", compatibleValue);
            }}
          />
          <FormCurrency
            name="amount"
            label={t("common:terms:amount")}
            currency={currency}
          />
          <FormDateTimePicker
            name="chargedAt"
            label={t("common:terms:charged_at")}
          />
          <FormSelect
            name="type"
            label={t("common:terms:type")}
            options={[
              { label: t("common:dicts:deposit_type:credit_card"), value: "credit_card" },
              { label: t("common:dicts:deposit_type:bank_transfer"), value: "bank_transfer" },
              { label: t("common:dicts:deposit_type:cash"), value: "cash" },
              { label: t("common:dicts:deposit_type:paypal"), value: "paypal" },
              { label: t("common:dicts:deposit_type:other"), value: "other" },
            ]}
          />
          <FormTextArea
            name="notes"
            label={t("common:terms:notes")}
          />
        </StyledForm>
      </ScrollableContainer>
      <FooterContainer>
        <SubmitButton layout={false}>
          {t("common:actions:save")}
        </SubmitButton>
      </FooterContainer>
    </BodyContainer>
  );
};
