import React from "react";
import { useFieldArray, useFormState } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Flex, Tag } from "antd";

import Legend from "components/forms/layout/legend/legend";

import useDrawer from "hooks/use_drawer";

import { EditDrawer } from "./edit_drawer";

const DepositItem = ({ deposit, errors, onRemove, onEdit }) => {
  const { t } = useTranslation();

  const isError = Object.keys(errors || {}).length !== 0;

  return (
    <Flex justify="space-between">
      <span>
        {isError && <Tag icon={<ExclamationCircleOutlined />} color="red">{t("general:validation_error")}</Tag>}
        {deposit.type}: {deposit.amount} {deposit.currency}
      </span>
      <span>
        <Button type="link" onClick={onEdit} icon={<EditOutlined />} />
        <Button type="link" danger onClick={onRemove} icon={<DeleteOutlined />} />
      </span>
    </Flex>
  );
};

const buildNew = ({ currency }) => ({
  currency,
});

export const Deposits = ({ name, currency }) => {
  const { t } = useTranslation();

  const { fields, append, remove, update } = useFieldArray({ name });
  const { isOpen, payload, open, close } = useDrawer();

  const { errors: formErrors } = useFormState();
  const errors = formErrors[name];

  return (
    <div>
      <Legend>
        {t("common:headings:deposits")}
        <Button
          icon={<PlusCircleOutlined />}
          type="link"
          onClick={() => open({ deposit: buildNew({ currency }) })}
        >
          {t("common:actions:create")}
        </Button>
      </Legend>

      {fields.map((field, index) => (
        <DepositItem
          key={field.id}
          deposit={field}
          errors={errors?.[index]}
          onRemove={() => remove(index)}
          onEdit={() => {
            open({
              deposit: field,
              index,
            });
          }}
        />
      ))}

      <EditDrawer
        visible={isOpen}
        deposit={payload?.deposit}
        errors={errors?.[payload?.index]}
        onClose={close}
        onSubmit={(data) => {
          if (payload.index === undefined) {
            append(data);
            return;
          }

          update(payload.index, data);
        }}
      />
    </div>
  );
};
