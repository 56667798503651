import React, { useState } from "react";
import { Divider, Typography } from "antd";

import { ReactQueryCrudTable } from "components/crud_table/react_query";
import { Select } from "components/forms/inputs/hook_form/_v2/select";
import { Actions } from "components/menu/actions";
import { editMenuItem } from "components/menu/items/edit";
import { removeMenuItem } from "components/menu/items/remove";

import useDrawer from "hooks/use_drawer";

import { ManageDrawer } from "./manage_drawer";
import { useDeleteMutation } from "./use_delete_mutation";
import { useUserRequestLimits } from "./use_user_request_limits";

export function UserRequestLimitsSubPage() {
  const [tableParams, setTableParams] = useState();
  const { isOpen, payload, open, close } = useDrawer();

  const query = useUserRequestLimits(tableParams);
  const deleteMutation = useDeleteMutation();

  const handleEditClick = (record) => () => {
    open(record);
  };

  const columns = () => {
    return [{
      title: "Type",
      dataIndex: "entity",
      key: "entity",
    }, {
      title: "Entity",
      key: "entity_label",
      render: (_value, record) => {
        if (record.entity === "user") {
          return record.user.email;
        }

        if (record.entity === "billing_account") {
          return record.billingAccount.companyName;
        }

        if (record.entity === "user_api_key") {
          return record.userApiKey.key;
        }
      },
    }, {
      title: "Rule",
      dataIndex: "rule",
      sorter: false,
      render: (_value, record) => {
        return (
          <div>
            <Typography.Text code>{record.path}</Typography.Text>
            <Divider type="vertical" />
            <Typography.Text type="secondary">{record.quota } req(s) per {record.timeframe} {record.timeframeType}(s)</Typography.Text>
          </div>
        );
      },
    }, {
      title: "Actions",
      key: "action",
      align: "right",
      width: 100,
      render: (_value, record) => (
        <Actions
          items={[
            editMenuItem({
              onClick: handleEditClick(record),
            }),
            removeMenuItem({
              onRemove: () => deleteMutation.mutate(record.id),
            }),
          ]}
        />
      ),
    }];
  };

  return (
    <>
      <ReactQueryCrudTable
        columns={columns}
        query={query}
        searchInput={false}
        searchAddon={(
          <Select
            style={{ width: 150 }}
            placeholder="Entity Type"
            allowClear
            options={[{
              value: "user",
              label: "User",
            }, {
              value: "billing_account",
              label: "Billing Account",
            }, {
              value: "user_api_key",
              label: "API Key",
            }]}
            onChange={(value) => {
              setTableParams((tp) => {
                if (!value) {
                  const newTableParams = { ...tp };

                  if (newTableParams.filters?.entity) {
                    delete newTableParams.filters.entity;
                  }

                  // if filters are empty, remove it
                  if (Object.keys(newTableParams.filters || {}).length === 0) {
                    delete newTableParams.filters;
                  }

                  return newTableParams;
                }

                const newTableParams = { ...tp };

                newTableParams.filters ||= {};
                newTableParams.filters.entity = value;

                return newTableParams;
              });
            }}
          />
        )}
        onCreateClick={() => open()}
        onTableParamsChange={setTableParams}
      />
      <ManageDrawer
        visible={isOpen}
        entity={payload}
        onClose={close}
      />
    </>
  );
}
