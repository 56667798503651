import i18n from "i18next";

import { message } from "../../../app";

export const showSuccessMessage = (successMessage, options = {}) => {
  const { t } = i18n;
  const { duration = 1 } = options;

  const defaultMessage = t("common:messages:request_successful");

  message.success(successMessage || defaultMessage, duration);
};
