import { stringifyQueryParams } from "../../routing/utils";

import { parseResponse } from "./_utils/parse_response";
import { httpClient } from "./http_client";

const ENDPOINT = "properties";

export const propertiesApiClient = {
  async fetchById(propertyId, options = {}) {
    const {
      relationships,
    } = options;

    let path = `${ENDPOINT}/${propertyId}`;
    let queryParams;

    if (relationships) {
      queryParams = { relationships };
    }

    if (queryParams) {
      path = `${path}${stringifyQueryParams(queryParams)}`;
    }

    const response = await httpClient.send("GET", path);

    return parseResponse(response);
  },
};
