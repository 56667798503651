import React from "react";

import SubmitButton from "components/forms/buttons/submit_button";
import GlobalErrors from "components/forms/global_errors";
import FormInput from "components/forms/inputs/hook_form/form_input";
import { TextValue } from "components/forms/inputs/text_value/text_value";
import BottomActions from "components/forms/layout/drawer/bottom_actions/bottom_actions";
import { useAppForm } from "components/hook_form";

import { validationSchema } from "./validation_schema";

export function WhiteLabelPartnerForm({ values, isLoading, onSubmit }) {
  const { handleSubmit, errors, control } = useAppForm({
    initialValue: values,
    validationSchema,
    fieldNames: ["title", "contactName", "contactEmail", "contactPhone", "agreementContentUrl", "agreementVersion"],
    submitHandler: onSubmit,
  });

  const isEdit = !!values?.id;

  return (
    <div>
      <div>
        <GlobalErrors hookForm errors={errors} />

        {isEdit && (
          <TextValue
            label="Id"
            value={values.id}
            copy
          />
        )}
        {isEdit && (
          <FormInput
            disabled
            name="status"
            label="Status"
            control={control}
          />
        )}
        <FormInput
          name="title"
          label="Title"
          errors={errors?.title?.message}
          control={control}
        />
        <FormInput
          name="contactName"
          label="Contact Person Name"
          errors={errors?.contactName?.message}
          control={control}
        />
        <FormInput
          name="contactEmail"
          label="Contact Person Email"
          errors={errors?.contactEmail?.message}
          control={control}
        />
        <FormInput
          name="contactPhone"
          label="Contact Person Phone"
          errors={errors?.contactPhone?.message}
          control={control}
        />
        <FormInput
          name="agreementContentUrl"
          label="Agreement Url"
          errors={errors?.agreementContentUrl?.message}
          control={control}
        />
        <FormInput
          name="agreementVersion"
          label="Agreement Version"
          errors={errors?.agreementVersion?.message}
          control={control}
        />
      </div>
      {isEdit && (
        <div>
          <legend>Internal Settings</legend>
          <pre>
            {JSON.stringify(values.internalSettings, null, 2)}
          </pre>
          <legend>Settings</legend>
          <pre>
            {JSON.stringify(values.settings, null, 2)}
          </pre>
        </div>
      )}

      <BottomActions>
        <SubmitButton onClick={() => { handleSubmit(); }} loading={isLoading}>
          Save
        </SubmitButton>
      </BottomActions>
    </div>
  );
}
