import React from "react";
import { useFieldArray, useFormState } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Flex, Tag } from "antd";

import Legend from "components/forms/layout/legend/legend";

import useDrawer from "hooks/use_drawer";

import { EditDrawer } from "./edit_drawer";

const ServiceItem = ({ service, errors, onRemove, onEdit }) => {
  const { t } = useTranslation();

  const isError = Object.keys(errors || {}).length !== 0;

  return (
    <Flex justify="space-between">
      <span>
        {isError && <Tag icon={<ExclamationCircleOutlined />} color="red">{t("general:validation_error")}</Tag>}
        {service.name}
      </span>
      <span>
        <Button type="link" onClick={onEdit} icon={<EditOutlined />} />
        <Button type="link" danger onClick={onRemove} icon={<DeleteOutlined />} />
      </span>
    </Flex>
  );
};

const buildNew = () => ({});

export const Services = ({ name, currency }) => {
  const { t } = useTranslation();

  const { errors: formErrors } = useFormState();
  const errors = formErrors[name];

  const { fields, append, remove, update } = useFieldArray({ name });
  const { isOpen, payload, open, close } = useDrawer();

  return (
    <div>
      <Legend>
        {t("common:headings:services")}
        <Button
          icon={<PlusCircleOutlined />}
          type="link"
          onClick={() => open({ service: buildNew() })}
        >
          {t("common:actions:create")}
        </Button>
      </Legend>

      {fields.map((field, index) => (
        <ServiceItem
          key={field.id}
          service={field}
          errors={errors?.[index]}
          onRemove={() => remove(index)}
          onEdit={() => {
            open({
              service: field,
              index,
            });
          }}
        />
      ))}

      <EditDrawer
        visible={isOpen}
        service={payload?.service}
        currency={currency}
        errors={errors?.[payload?.index]}
        onClose={close}
        onSubmit={(data) => {
          if (payload.index === undefined) {
            append(data);
            return;
          }

          update(payload.index, data);
        }}
      />
    </div>
  );
};
