import i18n from "i18next";

// TODO: looks like there is a race condition between i18n init and usages in validation schemas

const validationMessages = {
  required: () => i18n.t("validation_messages:required") || "required",
  invalid: () => i18n.t("validation_messages:invalid"),
  number: () => i18n.t("validation_messages:number"),
  email: () => i18n.t("validation_messages:email"),
  positive: () => i18n.t("validation_messages:positive_number"),
  nonNegative: () => i18n.t("validation_messages:non_negative_number"),
  integer: () => i18n.t("validation_messages:integer"),
  maxDecimalPlaces: (max) => i18n.t("validation_messages:max_decimal_places", { max }),
  minDecimalPlaces: (min) => i18n.t("validation_messages:min_decimal_places", { min }),
  less: (max) => i18n.t("validation_messages:less", { max }),
  lessOrEqual: (max) => i18n.t("validation_messages:less_or_equal", { max }),
  greater: (min) => i18n.t("validation_messages:greater", { min }),
  greaterOrEqual: (min) => i18n.t("validation_messages:greater_or_equal", { min }),
  maxLength: (max) => i18n.t("validation_messages:max_length", { max }),
  minLength: (min) => i18n.t("validation_messages:min_length", { min }),
  passwordMatch: () => i18n.t("validation_messages:password_match"),
};

export default validationMessages;
export { validationMessages };
